import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
//import AboutProject from '../../../../../components/case-studies/AboutProject'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'

class HledejRemeselnika extends Component {
  render() {
    const { data } = this.props
    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Hledej řemeslníka'}
          description={'Prípadová štúdia'}
          description2={'Českému start-upu sme sa navrhli niekoľko verzií ich budúceho nového loga a postarali sa o kompletný branding.'}
          text={'Profesionálny branding rozpráva príbeh značky a to je naším zámerom pri každom klientovi. Okrem toho, že vystihuje jeho cieľ a víziu, zviditeľňuje ju na trhu, čím sa stáva rýchlo zapamätateľnou. Klientovi sa náš profesionálny tím grafikov postaral o výstižný logo návrh a branding.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'tvorba logotypu', link: '/nase-prace/grafika/logo-branding/hledejremeslnikacz-branding' },
            { text: 'logomanuál' },
            { text: 'designmanuál' },
          ]}
          noShadow={true}
          className={'right-mode'}
        />

        {/*<AboutProject
          text={'Spolupráca s Hledej remeselníka prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />*/}

        <ProjectShortDescription
          className={'no-top-margin'}
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#e05430', tintColorCode: '#e67659', nameColor: '#FFFFFF', name: 'Rumelková červená' },
          { code: '#213c4b', tintColorCode: '#4d636f', nameColor: '#FFFFFF', name: 'Tmavá petrolejová' },
          { code: '#ffffff', tintColorCode: '#ffffff', nameColor: '#6B7B8A', name: 'Biela' },
        ]} />
        <FontShowcase
          fontName={'Avenir Next LT'}
          text={'Rodina písiem Avenir Next® bola nadizajnovaná Adrianom Frutigerom v spolupráci s vedúcim typu Monotype Akirom Kobayashim. <br/> <br/> Je to rozšírená prerábka pôvodnej rodiny písiem (vydaných ako OpenType písmo), ktorá získala verejné uznanie po publikovaní v Linotype v roku 2004.'}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/avenir.svg'}
        />

        <Reveal>
          <div className={'p-0 HledejRemeselnika-background'}>
            <div className={'container p-0'}>
              <Img className={'mb-5'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
            </div>
          </div>
        </Reveal>

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Inspirativni'}
          nextProjectUrl={'/nase-prace/pripadove-studie/grafika/logo-branding/inspirativni/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  landingImage: file(relativePath: { eq: "case-studies/HledejRemeselnika_landing.png" }) {
    childImageSharp {
      fluid( maxWidth: 1600, quality: 95) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
    childImageSharp {
      fixed( width: 250) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  pageOne: file(relativePath: { eq: "case-studies/1_tatryweb.jpg" }) {
    childImageSharp {
      fluid( maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  pageTwo: file(relativePath: { eq: "case-studies/4_tatryweb.jpg" }) {
    childImageSharp {
      fluid( maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  pageThree: file(relativePath: { eq: "case-studies/6_tatryweb.jpg" }) {
    childImageSharp {
      fluid( maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  logos: file(relativePath: { eq: "case-studies/HledejRemeselnika_logos.png" }) {
    childImageSharp {
      fluid( maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  corporateIdentity: file(relativePath: { eq: "case-studies/HledejRemeselnika_ci.png" }) {
    childImageSharp {
      fluid( maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`

export default HledejRemeselnika
